import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Next Stage Framework (NSF)",
  "description": "NSF (Next Stage Framework) is NexPCB's take on the innovation, product, and business development framework to success. ",
  "author": "",
  "categories": ["onboarding"],
  "date": null,
  "featured": true,
  "tags": ["nsf", "onboarding"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`NSF (Next Stage Framework) is NexPCB's unique take to simplify the stages of innovation, product development, and production, also business advancement stages.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.22007722007721%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABJ0AAASdAHeZh94AAADeklEQVQ4y1WOC0/bZhiF/du3aVTtNA0mGJTSldJC10JbcSmFhEvIjcSB3C+2k5hwCXFix06cOHEceKakaqW+0vMd6dN7znuEx8dHJvNdy4rCyWmASChILC6SSIiEgkGi5zFSyUvC4QiiKHIaCKBpzZ+8kxEmz8PDA+PxeKqO08e2bTqdDqZp0u120TQN07To9WzapollWVOGrvsjcOKdBg6HQ1zXxfO8H/R6PTxv/OPQd0O/7zDZdxxnujNhEjzRyeGJV5g00vXWtEVTa6C3Wth2F0WWyGWzZDNpUqkUxWIRyzKnLQyjhSxLKIpMLpfFtEweHx8YjUYIg+GQ+4ZOVb2hdl1Hrd3hDDy8MdzeaSSTWSrqLa4HfWdEr++iNdvcazo9x6VrD7irN9Fa5reGvW6bYUfmoV9l3K/idhWMeoqBJeF2ZQZWaYrbkTEbGYx6koy4Rzq+i1FP06iJXIS3UPInjL0hgtut0SvMcBv7hUbiV+ziU7zqLOPqHI3E7+ipJzQvZvAqszxe/Y2d/4N7cYZa9Des7DOozTNW5xmVZ/EGBkLHuuNa2UCV3lEtbVCT31GRXqIqLyjmFinlFpGLz1GVFarKCqX8EunkPGJ8jlx6AbW8QkVeRi4uMRiYCHdWl//kWzbLd99Q6jzPH/Cn+IongWWehl4wE1jmWeQl87n3vLzaZrG0yXNpixV1m4XCB+ZS68yl12kPOgiNrs0HSeFtMsPrxCUfijJvckFe5w/4J/KJv3zrLIQ+spb38W/6K2uFI94UjnlbPGGt4Gc1c8hyfIcXF3uYjo1Qb1vsSHW+VjT2yxpflHvenNZY2q6wtC2x8KnI8l6FhY8FVv11Poku62cG6wGDrdiAjWCbV75bVv0NrN4IQdcH+PxtDg41Dv06hz6dvWCLfbHDe5/K2naW9/4r9uMWn8/u2Y0a7EV19qIGu1Gd7VCDzaMaWyc3dHsugqE7RM7aRIIG0VCb4GmLazFPNeBHOj6ifHpM6eiQ8skxrcsEdi5NQ4yhiTE6mRSdTJJmIk7rQmTk9BF6ho4uxrgJBbkKnNIU4zTPw1SOfVNSO58pHx2invjQYhHq4TPy+7uUDr5wGz6jk0ygnYdpxiKM+n2EoW1jKRKtYp5GPoshFelUFJyaiq1WqCUvscoKg+urqZqKjKlIWBWFtiJNd7/9S3jDIf8DRoQk1LPDaxUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7ea75dd664ca44f09b039cc3cb9dfa41/652f5/nsf-01.webp 259w", "/static/7ea75dd664ca44f09b039cc3cb9dfa41/c29d2/nsf-01.webp 518w", "/static/7ea75dd664ca44f09b039cc3cb9dfa41/83245/nsf-01.webp 598w"],
            "sizes": "(max-width: 598px) 100vw, 598px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7ea75dd664ca44f09b039cc3cb9dfa41/a2ead/nsf-01.png 259w", "/static/7ea75dd664ca44f09b039cc3cb9dfa41/6b9fd/nsf-01.png 518w", "/static/7ea75dd664ca44f09b039cc3cb9dfa41/0c69d/nsf-01.png 598w"],
            "sizes": "(max-width: 598px) 100vw, 598px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/7ea75dd664ca44f09b039cc3cb9dfa41/0c69d/nsf-01.png",
            "alt": "nsf 01",
            "title": "nsf 01",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`The framework will be commonly used for hardware startups, in which they'll need to first do some prototyping, funding, and molding, before finally launching their product in the market.`}</p>
    <p>{`We generally segment the stages into 6, based on the innovation curve stages:`}</p>
    <p><strong parentName="p">{`S0:`}</strong>{` The S0 stage is the starting point. Whenever you're starting a project or creating a new product, you'll always first start here. It's also the `}<em parentName="p">{`ideation stage`}</em>{`. During this stage, you'll spend a lot of time designing, researching, and learning about what to do, when to do it, and how to optimize your design.`}</p>
    <p>{`At this stage, our `}<a parentName="p" {...{
        "href": "https://knowledgebase.nexpcb.com/articles/innovation-consulting/",
        "title": "innovation consulting"
      }}><strong parentName="a">{`innovation consulting `}</strong></a>{`service will help you prepare for your product launch`}</p>
    <p><strong parentName="p">{`S1-S2:`}</strong>{` During these stages, continuous, iterative prototyping are done to finally establish a valid `}<strong parentName="p">{`Minimum Viable Product (MVP)`}</strong>{` and `}<strong parentName="p">{`Proof of Concept (POC).`}</strong>{` Using the MVP, you'll need to focus on `}<strong parentName="p">{`securing funding`}</strong>{` to move to the next round of production.`}</p>
    <p>{`At this stage, our `}<a parentName="p" {...{
        "href": "https://knowledgebase.nexpcb.com/articles/new-technology-introduction-nti/",
        "title": "NTI (New Technology Introduction)"
      }}><strong parentName="a">{`NTI (New Technology Introduction`}</strong></a><strong parentName="p">{`)`}</strong>{` service will help you prototype and lay out the foundations for your product. `}</p>
    <p><strong parentName="p">{`S3:`}</strong>{` In the S3 stage, hardware products generally start to create a mold for their plastic parts, and optimize their casings or enclosures. Typically, a QC standard approach will be established at this stage.`}</p>
    <p>{`At this stage, our `}<a parentName="p" {...{
        "href": "https://knowledgebase.nexpcb.com/articles/new-product-introduction-npi/",
        "title": "NPI (New Product Introduction)"
      }}><strong parentName="a">{`NPI (New Product Introduction) `}</strong></a>{`service will help you optimize your product launch, and get you a strong start in the market. `}</p>
    <p><strong parentName="p">{`S4-S6:`}</strong>{` In the last 3 stages, the production volume is increased step by step. During this time, the quality, cost, and time for production will be optimized. Ensuring that the production cycle can occur at the optimum pace.`}</p>
    <p>{`At this final stage, our `}<a parentName="p" {...{
        "href": "https://knowledgebase.nexpcb.com/articles/lifecycle-management/",
        "title": "Lifecycle Management"
      }}><strong parentName="a">{`Lifecycle Management `}</strong></a>{`service will integrate your production, warehouse, and supply chain efforts so you can focus on growing your brand in the global market. `}</p>
    <h3 {...{
      "id": "contact-us"
    }}>{`Contact us`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      